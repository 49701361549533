/* Global styles index.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

/* Verwijder of commentarieer de volgende regels uit als ze bestaan */
/*
body {
  background-color: #f5f5f5;
}

.draggableContainer {
  background-color: white;
}
*/

/* Voeg deze regels toe om er zeker van te zijn dat de thema-instellingen worden toegepast */
body, .draggableContainer {
  transition: background-color 0.3s ease, color 0.3s ease;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Slab', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

.App {
  text-align: center;
  background-image: url('./assets/background-pattern.png');
  background-repeat: inherit;
  margin: 0;
  padding-bottom: 10px; 
}

/* CSS */
.logo {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 50%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh; /* Verlaag de minimale hoogte van de header */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px; /* Verander de lettergrootte naar een vaste waarde */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: lg;
    padding: 0px;
  }

.paper {
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50px;
}


.typographyHeader {
  margin-bottom: 20px;
  font-family: 'Roboto Slab', serif; 
}
.typographyHeader h4 {
    font-family: 'Roboto Slab', serif; 
}

.typographyHeader h5 {
  font-family: 'Roboto Slab', serif; 
}

.formatinstructionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.instructionsContainer,
.formatSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  margin-bottom: 0px;
}

.draggableContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px 0 10px 0;
}

.dragHandleContainer {
  margin-right: 8px;
  cursor: grab;
}




@media (min-width: 768px) {
  .instructionsContainer,
  .formatSelect {
    width: 100%;
  }
}
.instructionsContainer .typographyHeader h3 {
    margin-bottom: 0px; /* Adjust this value as needed */
}

.instructionsContainer ul {
    list-style-type: none; /* Verwijder de standaard lijststijl */
    padding: 0; /* Verwijder de standaard padding */
    margin-top: 0; /* Remove the top margin */
}

.instructionsContainer ul li {
    margin-bottom: 0px; /* Voeg een beetje ruimte toe tussen de lijstitems */
}

.dialogTitle .typographyHeader h3 {
  margin-bottom: 10px; /* Adjust this value as needed */
}

.dialogContent ul {
  margin-top: 0; /* Remove the top margin */
}

.buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
}

.addRemoveButton {
  margin-right: 10px;
}

.submitButton {
 margin-top: 0px;
}

.typographyHeader {
  margin-bottom: 20px;
}

.AudioSettingsForm {
  padding-top: 20px;
  padding-bottom: 20px;
}

.chatFormButton button {
  /* Voeg hier je stijlen toe */
  /* background-color: #4CAF50; /* bijvoorbeeld, verander de achtergrondkleur */
  /* color: white; /* verander de tekstkleur */
  padding: 15px 32px; /* voeg padding toe */
  text-align: center; /* centreer de tekst */
  text-decoration: none; /* verwijder onderstreping */
  display: inline-block;
  /* font-size: 16px; */
  display: flex;
align-items: center;
justify-content: center;
width: 100%;
margin-bottom: 20px;
margin-top: 40px;
  border-radius: 10px; /* maak de hoeken rond */
}


.SubprojectSettings {
  padding-top: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* SentenceForm styles */

.sentenceForm {
  background-color: #ffffff;
  border-radius: 0px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
  padding: 20px 0px 0px 0px;
  margin-bottom: 0px;
  transition: all 0.3s ease;
  position: relative;
  width: 100%;
  border: 0px;
}

/*.sentenceForm:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
} */

.preVoiceSection,
.postVoiceSection,
.bgVoiceSection,
.voiceControls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiAutocomplete-root {
  flex-grow: 1;
}

.MuiSlider-root {
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
}

.MuiIconButton-root {
  padding: 5px;
}

.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.removeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 600px) {
  .sentenceForm {
    padding: 15px;
  }

  .preVoiceSection,
  .postVoiceSection,
  .bgVoiceSection,
  .voiceControls {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Stijl voor de play button en dropdown icon in de Select component */
.selectIcons {
  display: flex;
  align-items: center;
}

.MuiSelect-select {
  padding-right: 48px !important; /* Ruimte maken voor de play button en dropdown icon */
}

.MuiSelect-icon {
  display: none;
}

.MuiSelect-root .MuiIconButton-root {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

/* Stijl voor Autocomplete */
.MuiAutocomplete-popupIndicator {
  display: none !important;
}

.MuiAutocomplete-endAdornment {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.audioTimeline {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* DragDropContext en Draggable stijlen */
.draggableContainer {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);

  cursor: grab;
  margin-bottom: 20px; /* Voeg wat ruimte toe tussen de items */
  border-radius: 20px; /* maak de hoeken rond */

}

.draggableContainer:hover {
  box-shadow: 0px 0px 10px #aaa;

}

/* DragHandleIcon container stijlen */
.dragHandleContainer {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media screen and (max-width: 800px) {
  .dragHandleContainer {
    min-width: 50px; /* Pas dit aan naar de gewenste minimale breedte */
  }
}

.textField {
  flex-grow: 1;
  flex-basis: 75%;
  width: 100%;
  margin-right: 10px;
  margin-top: 10px;
}

.formControls {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.formControl {
  display: flex;
}